<template>
  <div class="handleLog">
    <div class="form-header-btn">
      <div class="header-title">伙食费</div>
      <div>
		<span class="sync">最后同步时间[{{this.syncTime}}]</span>
		<el-button
		    type="primary"
		    class="sheet-btn"
		    @click="syncData"
		  >
		    同步
		  </el-button>
        <el-button
          type="primary"
          class="sheet-btn"
          @click="downLoadFile"
          v-if="isExport"
        >
          导出
        </el-button>
      </div>
    </div>
    <!-- 表单 -->
    <el-form :model="form" onsubmit="return false" :inline="true">
      <!-- <el-form-item v-if="operate == 1">
        <el-select v-model="form.parkId" clearable placeholder="请选择园校">
          <el-option
            v-for="item in moduleParkOptions"
            :key="item.label"
            :label="item.name"
            :value="item.label"
          >
          </el-option>
        </el-select>
      </el-form-item> -->
      <el-form-item>
        <el-select
          v-model="form.isOverspend"
          clearable
          placeholder="请选择是否超支"
        >
          <el-option
            v-for="item in handleOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item v-if="operate == 0">
        <el-select v-model="form.studyYear" placeholder="请选择学年">
          <el-option
            v-for="item in handleStudyYear"
            :key="item"
            :label="item"
            :value="item"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item v-if="operate == 0">
        <el-select
          v-model="form.beginMoth"
          placeholder="开始月份"
          @change="handleChangeBeginMoth"
        >
          <el-option
            v-for="item in handleStudyBeginMoth"
            :key="item"
            :label="item"
            :value="item"
          >
          </el-option>
        </el-select>
        —
        <el-select
          v-model="form.endMoth"
          placeholder="结束月份"
          :disabled="endMothDisabled"
        >
          <el-option
            v-for="item in handleStudyEndMoth"
            :key="item.value"
            :label="item.value"
            :value="item.value"
            :disabled="item.disabled"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <!-- <el-form-item v-if="operate == 0">
        <el-date-picker
          v-model="form.dateTime"
          type="monthrange"
          range-separator="～"
          value-format="yyyy-MM"
          start-placeholder="开始上报日期"
          end-placeholder="结束上报日期"
        >
        </el-date-picker>
      </el-form-item> -->

      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="searchFun">
          查询
        </el-button>
      </el-form-item>

      <el-form-item>
        <el-button @click="resetFun" icon="el-icon-refresh-left" class="reset">
          重置
        </el-button>
      </el-form-item>
    </el-form>
    <!-- 表格 -->
    <el-table
      :data="tableData"
      :header-cell-style="{
        background: '#F1F4F9',
        color: '#5E6D9B',
        fontWeight: '400',
        'text-align':'center'
      }"
      :summary-method="getSummaries"
      show-summary
      v-loading="tableLoading"
      @sort-change="sortChange"
    >
      <el-table-column
        type="index"
        label="序号"
        min-width="80"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="month"
        label="月份"
        min-width="120"
        align="center"
      ></el-table-column>
	  <el-table-column
	    prop="spendingTarget"
	    label="目标额(元)"
	    min-width="150"
	    align="center"
	  ></el-table-column>
	  <el-table-column
	    prop="spendingBudget"
	    label="预算额(元)"
	    min-width="150"
	    align="center"
	  ></el-table-column>
      <el-table-column
        prop="revenueSettlement"
        label="收入结算(元)"
        min-width="150"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="expenseSettlement"
        label="支出结算(元)"
        min-width="150"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="executionRatio"
        label="执行比"
        sortable="custom"
        min-width="100"
        align="center"
      >
        <template slot-scope="{ row }">
          <span :class="row.isOverspend.value == '1' ? 'activeColor' : ''">
            {{ row.executionRatio }}
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="isOverspend" label="是否超支" min-width="100" align="center">
        <template slot-scope="{ row }">
          <span :class="row.isOverspend.value == '1' ? 'activeColor' : ''">
            {{ row.isOverspend.label }}
          </span>
        </template>
      </el-table-column>
      <el-table-column
        prop="inParkNum"
        label="在园人数"
        min-width="100"
        align="center"
      ></el-table-column>
    </el-table>

    <!-- 分页 -->
    <div class="manger-footer">
      <div class="footer-pagination">
        <el-pagination
          background
          @size-change="handleSizeChange"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pagination.total"
          :page-size="pagination.size"
          @current-change="handleCurrentChangePage"
          :current-page.sync="pagination.currentPage"
        >
        </el-pagination>
      </div>
      <!-- <div class="footer-pagination">
        <el-pagination
          background
          @size-change="handleSizeChange"
          layout=" sizes, prev, pager, next, jumper"
          :total="pagination.total"
          :page-size="pagination.size"
          @current-change="handleCurrentChangePage"
          :current-page.sync="pagination.currentPage"
        >
        </el-pagination>
      </div> -->
    </div>
  </div>
</template>

<script>
import downloadFile from "@/utils/downloadFile";
import { getPermissionButton } from "@/utils/getUrlPermissionButtons";
import { mapGetters, mapState } from "vuex";

export default {
  name: "",
  data() {
    return {
      form: {
        parkId: "",
        isOverspend: "",
        studyYear: "", //学年
        beginMoth: "", //开始约
        endMoth: "", //结束约
      },
	  syncTime:"", //最后同步时间
      beginTime: "", //开始时间
      endTime: "", //结束时间
      beginTimeChuan: "",
      endTimeChuan: "",
      handleStudyYear: [], //学年
      handleStudyBeginMoth: [
        "09",
        "10",
        "11",
        "12",
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
      ], //开始月份
      handleStudyEndMoth: [
        {
          value: "09",
          disabled: false,
        },
        {
          value: "10",
          disabled: false,
        },

        {
          value: "11",
          disabled: false,
        },

        {
          value: "12",
          disabled: false,
        },

        {
          value: "01",
          disabled: false,
        },

        {
          value: "02",
          disabled: false,
        },

        {
          value: "03",
          disabled: false,
        },
        {
          value: "04",
          disabled: false,
        },
        {
          value: "05",
          disabled: false,
        },
        {
          value: "06",
          disabled: false,
        },
        {
          value: "07",
          disabled: false,
        },
        {
          value: "08",
          disabled: false,
        },
      ], //结束月份
      endMothDisabled: true,
      handleOptions: [
        { label: "是", value: "1" },
        { label: "否", value: "0" },
      ], // 操作类型下拉
      moduleParkOptions: [], //园校下拉
      tableData: [],
      pagination: {
        total: 0, // 总页数
        currentPage: 1, // 当前页数
        size: 10, // 每页显示多少条
      },
      tableLoading: false, // 表格loading
      listCount: [],
      orgId: "",
      branchId: "",
      schoolId: "",
      operate: 0,
      // disabledPark: true,
      // NowYearMonth: "", //当前年月
      permissionButtonList: [], // 权限按钮list
      isExport: false,
    };
  },

  computed: {
    ...mapState({
      menuList: (state) => state.common.menuList,
      userInfo: (state) => state.userInfo,
    }),
  },

  watch: {
    permissionButtonList: {
      handler(val) {
        if (val) {
          // console.log(val, "huoshifeipermissionButtonList");
          this.isExport = val.indexOf("y_foodExpense:export") != -1;
        }
      },
      deep: true,
    },
    menuList: {
      handler(val) {
        if (val) {
          this.getPermissionButton(this.$route.path, (data) => {
            this.permissionButtonList = data;
          });
        }
      },
      deep: true,
    },
  },

  created() {
    // menulist一直在时这个方法不可缺少
    this.getPermissionButton(this.$route.path, (data) => {
      this.permissionButtonList = data;
    });
  },

  mounted() {
    if (this.userInfo.schoolList.length > 0) {
      this.branchId = this.userInfo.schoolList[0].companyLabel;
      this.getSchoolList(this.branchId);
    }

    if (this.userInfo.schoolList.length > 0) {
      this.schoolId = this.userInfo.schoolList[0].schoolLabel;
    }

    // if (this.$route.query.operate) {
    //   this.operate = this.$route.query.operate;
    // } else {
    //   this.getSchoolList("");
    // }

    if (this.$route.query.beginTime) {
      this.beginTimeChuan = this.$route.query.beginTime;
      this.endTimeChuan = this.$route.query.endTime;
    }

    this.doHandleDate();
    // this.init();
  },
  activated(){
    this.init();
  },


  methods: {
    getPermissionButton,
    //排序
    sortChange(column) {
      // console.log(column);
      // console.log(column.prop, column.order);
      this.init(column.order);
    },

    //获取当前年月
    doHandleDate() {
      var myDate = new Date();
      var tYear = myDate.getFullYear();
      var tMonth = myDate.getMonth();

      var m = tMonth + 1;
      if (m.toString().length == 1) {
        m = "0" + m;
      }
      const tyeart = tYear + "-" + (tYear + 1);
      const tyeary = tYear - 1 + "-" + tYear;

      if (m < 9) {
        this.form.studyYear = tyeary;
      } else {
        this.form.studyYear = tyeart;
      }
      this.form.beginMoth = "09";
      this.form.endMoth = m;

      this.handleStudyYear.push(tyeary, tyeart);

      if (this.$route.query.beginTime && this.$route.query.endTime) {
        this.beginTime = this.$route.query.beginTime;
        this.endTime = this.$route.query.endTime;
      }
    },

    //开始月份下标
    handleChangeBeginMoth(val) {
      // this.form.beginMoth = val;
      this.endMothDisabled = false;
      this.form.endMoth = "";
      const EndMothIndex = (this.handleStudyBeginMoth || []).findIndex(
        (item) => item === val
      );

      this.handleStudyEndMoth.forEach((v, index) => {
        v.disabled = false;
        if (EndMothIndex > index) {
          v.disabled = true;
        }
      });
    },

    /** 初始化 */
    init(order) {
      const beginYear = this.form.studyYear.substring(0, 4);
      const endYear = this.form.studyYear.substring(5, 10);

      if (this.form.beginMoth < 9) {
        this.beginTime = endYear + "-" + this.form.beginMoth;
      } else {
        this.beginTime = beginYear + "-" + this.form.beginMoth;
      }

      if (this.form.endMoth < 9) {
        this.endTime = endYear + "-" + this.form.endMoth;
      } else {
        this.endTime = beginYear + "-" + this.form.endMoth;
      }
      this.tableLoading = true;
      let params = {
        sort: order,
        schoolId: this.schoolId,
        orgId: this.orgId,
        parkId: this.form.parkId,
        isOverspend: this.form.isOverspend,
        beginTime: this.beginTimeChuan || this.beginTime,
        endTime: this.endTimeChuan || this.endTime,
        current: this.pagination.currentPage,
        size: this.pagination.size,
      };

      this.$api
        .getParkPageLog(params)
        .then((res) => {
          if (res.data.code == "0") {
            this.tableData = res.data.data.list.records;
            this.listCount = res.data.data.listCount[0];
            this.pagination.total = res.data.data.list.total;
			this.syncTime=res.data.data.list.records[0].createTime;
            this.tableLoading = false;
          } else {
            this.tableLoading = false;
            this.$message.error(res.data.msg);
          }
        })
        .catch(() => {
          this.tableLoading = false;
        });
    },

    //查询园校
    getSchoolList(valCompany) {
      let params = {
        parent: valCompany,
        type: "NODE_SCHOOL",
      };
      this.$api.getCompanyDept(params).then((res) => {
        if (res.data.code == "0") {
          this.moduleParkOptions = res.data.data;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },

    //表尾合计
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "合计";
          return;
        }
        if (index === 2) {
          sums[index] = this.listCount.spendingTarget;
          return;
        }
        if (index === 3) {
          sums[index] = this.listCount.spendingBudget;
          return;
        }
        if (index === 4) {
          sums[index] = this.listCount.revenueSettlement;
          return;
        }
        if (index === 5) {
          sums[index] = this.listCount.expenseSettlement;
          return;
        }
        if (index === 6) {
          sums[index] = this.listCount.executionRatio;
          return;
        }

        if (index === 7) {
          if (this.listCount.isOverspend) {
            sums[index] = this.listCount.isOverspend.label;
          }
        }

        // if (index === 8) {
        //   sums[index] = "园校详情";
        // }
      });

      return sums;
    },

    //导出
    downLoadFile() {
      this.tableLoading = true;
      //下载文件
      let url = "/mng/mealExpenses/meal-expenses/exportPark";
      let data = {
        schoolId: this.schoolId,
        orgId: this.orgId,
        parkId: this.form.parkId,
        isOverspend: this.form.isOverspend,
        beginTime: this.beginTimeChuan || this.beginTime,
        endTime: this.endTimeChuan || this.endTime,
      };

      let type = "get";

      downloadFile({ url, data, type }).then(() => {
        this.tableLoading = false;
      });
    },

    // 查询
    searchFun() {
      this.pagination.currentPage = 1;
      this.tableLoading = true;
      this.init();
    },

    // 重置
    resetFun() {
      this.form = {
        parkId: "",
        isOverspend: "",
        studyYear: "", //学年
        beginMoth: "", //开始约
        endMoth: "", //结束约
      };

      this.handleStudyYear = [];
      this.endMothDisabled = true;
      this.pagination.currentPage = 1;
      this.tableLoading = true;
      this.doHandleDate();
      this.init();
    },

    /** 分页 */
    handleCurrentChangePage(val) {
      this.pagination.currentPage = val;
      this.tableLoading = true;
      this.init();
    },

    handleSizeChange(val) {
      this.pagination.size = val;
      this.tableLoading = true;
      this.init();
    },
	// type 取值说明 调groupPage接口取：1
	// type 取值说明 调branchPage接口取：2
	// type 取值说明 调parkPage接口取：3
	syncData(){
		this.tableLoading = true;
		let params = {
		  schoolId: this.schoolId,
		  orgId: this.orgId,
		  parkId: this.form.parkId,
		  beginTime: this.beginTime,
		  endTime: this.endTime,
		  type: "3",
		};
		this.$api
		  .getSyncMeal(params)
		  .then((res) => {
		    if (res.data.code == "0") {
		      this.searchFun();
		    } else {
		      this.$message.error(res.data.msg);
		      this.tableLoading = false;
		    }
		  })
		  .catch(() => {
		    this.tableLoading = false;
		  });
	},
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/css/select_list.scss";
.handleLog {
  padding: 26px 30px 30px 30px;

  .form-header-btn {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;

    .sheet-btn {
      background: #2a00b2;
      box-shadow: 0px 3px 10px rgba(52, 85, 236, 0.4);
      opacity: 1;
      border-radius: 4px;
      font-size: 14px;
      color: #ffffff;
    }

    /deep/.el-button + .el-button {
      margin-left: 20px;
    }
  }

  /deep/.el-table {
    // margin-top: 14px;
  }

  /deep/.el-dialog__body {
    padding: 0 20px 30px;
  }

  .dialog-sheetbtn {
    margin: 10px 0 20px;
  }
}

.manger-footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 30px;
  padding-bottom: 1px;

  .footer-left-title {
    color: #606266;
  }
  .footer-left-num {
    color: red;
  }
}

.allData {
  width: 300px;
  white-space: normal;
}

.handleData {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.activeColor {
  color: red;
}
.mark-title {
  margin-right: 10px;
}
.sync{
	font-size: 16px;
	margin-right: 18px;
	color:#003685;
}

// .manger-footer {
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   margin-top: 30px;
//   padding-bottom: 5px;

//   .footer-left-title {
//     color: #606266;
//   }
//   .footer-left-num {
//     color: red;
//   }
// }

// .allData {
//   width: 300px;
//   white-space: normal;
// }
// .handleData {
//   overflow: hidden;
//   text-overflow: ellipsis;
//   white-space: nowrap;
// }
</style>
